import { useLiveAPIContext } from '../../contexts/LiveAPIContext';
import './WaveAnimation.scss';
import cn from 'classnames';

export const WaveAnimation = () => {
  const { connected } = useLiveAPIContext();
  
  const waveStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/wave_final3.svg)`,
    backgroundRepeat: 'repeat-x'
  };
  
  return (
    <div className={cn('wave-container', { active: connected })}>
      <div className="wave" style={waveStyle}></div>
      <div className="wave" style={waveStyle}></div>
      <div className="wave" style={waveStyle}></div>
    </div>
  );
}; 