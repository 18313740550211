

export type GetAudioContextOptions = AudioContextOptions & {
  id?: string;
};

const map: Map<string, AudioContext> = new Map();

export const audioContext: (
  options?: GetAudioContextOptions,
) => Promise<AudioContext> = (() => {
  let didInteractResolve: () => void;
  const didInteract = new Promise<void>((resolve) => {
    didInteractResolve = resolve;
  });

  // Add event listeners that will mark user interaction
  window.addEventListener("pointerdown", () => didInteractResolve(), { once: true });
  window.addEventListener("keydown", () => didInteractResolve(), { once: true });

  return async (options?: GetAudioContextOptions) => {
    // Wait for user interaction before proceeding
    await didInteract;

    if (options?.id && map.has(options.id)) {
      const ctx = map.get(options.id);
      if (ctx) {
        if (ctx.state === "suspended") {
          await ctx.resume();
        }
        return ctx;
      }
    }

    const ctx = new AudioContext(options);
    if (ctx.state === "suspended") {
      await ctx.resume();
    }
    
    if (options?.id) {
      map.set(options.id, ctx);
    }
    return ctx;
  };
})();

export const blobToJSON = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } else {
        reject("oops");
      }
    };
    reader.readAsText(blob);
  });

export function base64ToArrayBuffer(base64: string) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
