import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Brand } from './Brand';
import './PrivacyPolicy.scss';

export const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="privacy-policy">
      <div className="top-bar">
        <button 
          onClick={() => navigate('/')} 
          className="back-button"
          aria-label="Back to home"
        >
          <span className="material-symbols-outlined">arrow_back</span>
        </button>
        <Brand />
      </div>

      <div className="privacy-policy__container">
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date().toLocaleDateString()}</p>

        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to callpauli.ai. This Privacy Policy explains how we collect, use, disclose, and safeguard your information
            when you use our AI-powered tech support service. Please read this privacy policy carefully. By using our service,
            you consent to the practices described in this policy.
          </p>
        </section>

        <section>
          <h2>Information We Collect</h2>
          <p>We collect several types of information for various purposes:</p>
          <ul>
            <li><strong>Account Information:</strong> Email address and authentication data through Supabase</li>
            <li><strong>Audio Data:</strong> Voice recordings during interactions with our AI agent</li>
            <li><strong>Screen Sharing Data:</strong> Visual data shared during support sessions</li>
            <li><strong>Usage Data:</strong> Information about how you use our service</li>
            <li><strong>Technical Data:</strong> Browser type, device information, and connection details</li>
          </ul>
        </section>

        <section>
          <h2>How We Use Your Information</h2>
          <p>We use the collected information for the following purposes:</p>
          <ul>
            <li>To provide and maintain our AI tech support service</li>
            <li>To process and respond to your queries through our AI agent</li>
            <li>To improve our service and develop new features</li>
            <li>To detect, prevent, and address technical issues</li>
            <li>To comply with legal obligations</li>
          </ul>
        </section>

        <section>
          <h2>Data Storage and Security</h2>
          <p>
            We use Supabase for secure user authentication and data storage. All data is encrypted in transit and at rest.
            We implement appropriate technical and organizational measures to protect your personal information against
            unauthorized access, alteration, disclosure, or destruction.
          </p>
        </section>

        <section>
          <h2>Data Retention</h2>
          <p>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this
            privacy policy. Audio recordings and screen sharing data are temporarily stored for the duration of the support
            session and are automatically deleted after processing, unless explicitly required for service improvement or
            legal compliance.
          </p>
        </section>

        <section>
          <h2>Your Rights</h2>
          <p>Under applicable data protection laws, you have the following rights:</p>
          <ul>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccurate personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>Right to restrict processing of your personal data</li>
            <li>Right to data portability</li>
            <li>Right to withdraw consent at any time</li>
          </ul>
        </section>

        <section>
          <h2>Third-Party Services</h2>
          <p>
            We use third-party services for authentication, data storage, and AI processing. These services have their own
            privacy policies and we recommend reviewing their policies to understand how they handle your information.
            Our main service providers include:
          </p>
          <ul>
            <li>Supabase for authentication and data storage</li>
            <li>Google AI services for natural language processing</li>
          </ul>
        </section>

        <section>
          <h2>Children's Privacy</h2>
          <p>
            Our service is not intended for use by children under the age of 13. We do not knowingly collect personal
            information from children under 13. If you become aware that a child has provided us with personal information,
            please contact us so we can take steps to remove such information.
          </p>
        </section>

        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our practices, please contact us at:
            jon@magicvillagelabs.com
          </p>
        </section>
      </div>
    </div>
  );
}; 