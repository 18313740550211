import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Brand } from './Brand';
import './LandingPage.scss';

export const LandingPage = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isMenuOpen && !target.closest('.mobile-menu') && !target.closest('.hamburger-button')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMenuOpen]);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  return (
    <div className="landing-page">
      <nav className={`landing-nav ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className="nav-brand">
          <Brand />
        </div>

        {/* Desktop Navigation */}
        <div className="nav-links desktop">
          {/* <a href="#" className="nav-link">How It Works</a>
          <a href="#" className="nav-link">FAQ</a> */}
          <button
            onClick={() => navigate('/auth')}
            className="sign-in-btn"
          >
            Sign in
          </button>
        </div>

        {/* Mobile hamburger button */}
        <button
          className="hamburger-button"
          onClick={toggleMenu}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          type="button"
        >
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        {/* Mobile menu */}
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="mobile-menu-content">
            <a href="#" className="nav-link">How It Works</a>
            <a href="#" className="nav-link">FAQ</a>
            <button
              onClick={() => navigate('/auth')}
              className="sign-in-btn"
            >
              Sign in
            </button>
          </div>
        </div>
      </nav>

      <main className="hero-section">
        <div className="hero-content">
          <h1>Tech Support for Life</h1>
          <p>
            Call Pauli — your tech support AI agent that uses the web, sees your screen, and speaks naturally to solve your tech problems and save you time. Speaks 30 languages, join the beta now.
          </p>
          <button
            onClick={() => navigate('/auth')}
            className="join-beta-btn"
          >
            Join the Beta
          </button>
          <div className="example-queries">
            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "Why is my internet slow?"
            </div>
            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "How do I use my printer?"
            </div>
            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "How do I use this website?"
            </div>
            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "What's a good router for my home?"
            </div>

            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "How do I transfer photos from my phone?"
            </div>
            <div className="query-item">
              <span className="material-symbols-outlined check-icon">done</span>
              "How do I attach a .pdf to an email and send it?"
            </div>
          </div>
        </div>
        <div className="hero-image">
          <img src="/pFinal.png" alt="AI Assistant Parrot" />
        </div>
      </main>

      <footer className="landing-footer">
        <div className="footer-content">
          <span>© {new Date().getFullYear()} Magic Village Labs, LLC.</span>
          <button onClick={() => navigate('/privacy')} className="privacy-link">
            See privacy policy
          </button>
        </div>
      </footer>
    </div>
  );
}; 