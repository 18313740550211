import { useNavigate, useLocation } from 'react-router-dom';
import './Brand.scss';

export const Brand = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  return (
    <div className="brand" onClick={handleClick} style={{ cursor: location.pathname !== '/' ? 'pointer' : 'default' }}>
      <div className="audio-equalizer">
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
      </div>
      callpauli.ai
    </div>
  );
}; 