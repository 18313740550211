import { useRef, useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import "./App.scss";
import { LiveAPIProvider, useLiveAPIContext } from "./contexts/LiveAPIContext";
import { Altair } from "./components/altair/Altair";
import ControlTray from "./components/control-tray/ControlTray";
import cn from "classnames";
import { AuthProvider } from "./contexts/AuthContext";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import { Header } from "./components/Header";
import { LandingPage } from "./components/LandingPage";
import { Auth } from "./components/auth/Auth";
import { WaveAnimation } from "./components/wave-animation/WaveAnimation";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

const API_KEY = process.env.REACT_APP_GEMINI_API_KEY as string;
if (typeof API_KEY !== "string") {
  throw new Error("set REACT_APP_GEMINI_API_KEY in .env");
}

const host = "generativelanguage.googleapis.com";
const uri = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;

function MainApp() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const { connected } = useLiveAPIContext();

  // Stop screen sharing when connection ends
  useEffect(() => {
    if (!connected && videoStream) {
      // Stop all tracks in the stream
      videoStream.getTracks().forEach(track => track.stop());
      // Clear the video stream
      setVideoStream(null);
    }
  }, [connected, videoStream]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-5xl mx-auto p-4 sm:p-6 lg:p-8">
        <div className="bg-white rounded-lg shadow">
          <div className="p-6">
            <Header />
            <div className="streaming-console">
              <main>
                <div className="main-app-area">
                  <Altair />
                  <video
                    className={cn("stream", {
                      hidden: !videoRef.current || !videoStream,
                    })}
                    ref={videoRef}
                    autoPlay
                    playsInline
                  />
                </div>

                <ControlTray
                  videoRef={videoRef}
                  supportsVideo={true}
                  onVideoStreamChange={setVideoStream}
                />
              </main>
            </div>
          </div>
        </div>
      </div>
      <WaveAnimation />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <LiveAPIProvider url={uri} apiKey={API_KEY}>
          <LandingPage />
        </LiveAPIProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/auth",
    element: (
      <AuthProvider>
        <LiveAPIProvider url={uri} apiKey={API_KEY}>
          <Auth />
        </LiveAPIProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/privacy",
    element: (
      <AuthProvider>
        <LiveAPIProvider url={uri} apiKey={API_KEY}>
          <PrivacyPolicy />
        </LiveAPIProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/app",
    element: (
      <AuthProvider>
        <LiveAPIProvider url={uri} apiKey={API_KEY}>
          <ProtectedRoute>
            <MainApp />
          </ProtectedRoute>
        </LiveAPIProvider>
      </AuthProvider>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
