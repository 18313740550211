import React, { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Brand } from './Brand';
import './Header.scss';

export function Header() {
  const { user, signOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSignOut = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await signOut();
    } catch (error) {
      console.error('Failed to sign out:', error);
    }
  }, [signOut]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isMenuOpen && !target.closest('.mobile-menu') && !target.closest('.hamburger-button')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMenuOpen]);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  if (!user) return null;

  return (
    <header className={`app-header ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="header-content">
        <div className="nav-brand">
          <Brand />
        </div>

        {/* Desktop view */}
        <div className="auth-section desktop">
          <div className="user-info">
            {user.user_metadata?.avatar_url && (
              <img 
                src={user.user_metadata.avatar_url} 
                alt="Profile" 
                className="avatar"
              />
            )}
            <span className="email">{user.email}</span>
          </div>
          <button 
            onClick={handleSignOut} 
            className="logout-button"
            type="button"
            aria-label="Sign out"
          >
            Sign Out
          </button>
        </div>

        {/* Mobile hamburger button */}
        <button 
          className="hamburger-button"
          onClick={toggleMenu}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          type="button"
        >
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        {/* Mobile menu */}
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="mobile-menu-content">
            <div className="user-info">
              {user.user_metadata?.avatar_url && (
                <img 
                  src={user.user_metadata.avatar_url} 
                  alt="Profile" 
                  className="avatar"
                />
              )}
              <span className="email">{user.email}</span>
            </div>
            <button 
              onClick={handleSignOut} 
              className="logout-button"
              type="button"
              aria-label="Sign out"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </header>
  );
} 