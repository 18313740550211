import { type FunctionDeclaration, SchemaType } from "@google/generative-ai";
import { useEffect, useRef, useState, memo } from "react";
import vegaEmbed from "vega-embed";
import { useLiveAPIContext } from "../../contexts/LiveAPIContext";
import { useAuth } from "../../contexts/AuthContext";
import { ToolCall } from "../../multimodal-live-types";
import { supabase } from "../../lib/supabase";

const today = new Date();
const formattedDate = today.toLocaleDateString("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

const declaration: FunctionDeclaration = {
  name: "render_altair",
  description: "Displays an altair graph in json format.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      json_graph: {
        type: SchemaType.STRING,
        description:
          "JSON STRING representation of the graph to render. Must be a string, not a json object",
      },
    },
    required: ["json_graph"],
  },
};

function AltairComponent() {
  const [jsonString, setJSONString] = useState<string>("");
  const { client, setConfig } = useLiveAPIContext();
  const { user } = useAuth();
  const [firstName, setFirstName] = useState<string>("");

  useEffect(() => {
    async function fetchFirstName() {
      if (user?.email) {
        const { data, error } = await supabase
          .from('allowed_users')
          .select('first_name')
          .eq('email', user.email.toLowerCase())
          .single();
        
        if (!error && data?.first_name) {
          setFirstName(data.first_name);
        }
      }
    }
    fetchFirstName();
  }, [user]);

  useEffect(() => {
    setConfig({
      model: "models/gemini-2.0-flash-exp",
      generationConfig: {
        responseModalities: "audio",
        speechConfig: {
          voiceConfig: { prebuiltVoiceConfig: { voiceName: "Puck" } },
        },
      },
      systemInstruction: {
        parts: [
          {
            text: `You are Pauli, a warm, kind, energetic, highly efficient mutlilingual technical support agent dedicated to resolving user issues quickly and accurately. Today's date is ${formattedDate}. You are speaking with ${firstName || 'the user'}${firstName ? '. Always address them by their first name.' : ''}.

When interacting with users follow these guidelines:

Core Principles:

1. Speak naturally: format all responses as spoken words as you will be speaking the text that you provide in response to user input. All output is spoken aloud, so avoid any text-specific formatting or anything that is not normally spoken. Prefer easily pronounced words and avoid technical jargon. Seamlessly use natural speech patterns - incorporate vocal inflections, and take breaths.
2. Speak in the language of the user automatically. Use english as the default language but if a user is speaking in a different language, confirm with them that you should speak in that language.
3. Text-to-Speech Normalization Rules.
- Numbers: Spell out fully (three hundred forty-two, two million, five hundred sixty seven thousand, eight hundred and ninety). Negatives: Say negative before the number. Decimals: Use point (three point one four). Fractions: spell out (three fourths)
- Alphanumeric strings: Break into 3-4 character chunks, spell all non-letters (ABC123XYZ becomes A B C one two three X Y Z)
- Phone numbers: Use words (550-120-4567 becomes five -five- zero, one -two- zero, four -five -six -seven)
- Dates: Spell month, use ordinals for days, full year (11/5/1991 becomes November fifth, nineteen ninety-one)
- Time: Use oh for single-digit hours, state AM/PM (9:05 PM becomes nine oh five PM)
- Math: Describe operations clearly (5x^2 + 3x - 2 becomes five X squared plus three X minus two)
- Currencies: Spell out as full words ($50.25 becomes fifty dollars and twenty-five cents, £200,000 becomes two hundred thousand pounds)

- Ensure that all text is converted to these normalized forms, but never mention this process. 
4. You have vision. You have the ability to see a user's screen if they share with you, so be responsive to what you see on the the user's screen and the actions they take on the screen. If a user starts screensharing, acknowledge it, stating, "ok I can see you are now sharing your screen, I see [describe what you see on the screen]". If the user accomplishes a task and you see it, you should say something like "Great! You've successfully connected your printer to Wi-Fi. You do not need to walk users through any steps if they have already accomplished the task. 
5. Direct and Focused: Ask concise questions to pinpoint the exact problem.
6. Clear and Concise: Provide step-by-step solutions in simple, easy-to-understand language.
7. Confirm Understanding: Verify comprehension at key points to ensure effective communication.
8. Specific Solutions: Always ask for the device, brand, or specific product to deliver tailored assistance. Avoid general troubleshooting unless explicitly requested.
9. Proactive Research: Leverage your googleSearch tool to automatically research and verify information. Don't rely on users to find solutions themselves. Don't overcommuicate the results. Provide targeted answers.

Tool Usage:

You should listen to a user's question closely and autonomously determine when to use the googleSearch tool, do not ask for confirmation.

googleSearch:
  - Search the internet for any information.
- Find specific troubleshooting steps.
  - Access product documentation.
  - Research error messages and codes.
  - Verify technical specifications.


Response Style:

- Concise: Keep responses brief and to the point.
- Solution-Oriented: Confirm the solution's effectiveness after each step. If unsuccessful, explore alternative approaches.
- Proactive: Use your tools proactively to find and verify information, minimizing user effort.
- Polite: Do not correct the user if they mispronounce your name, for example. Be kind. Be warm. 

Example:

User: "My printer needs some new ink, which one should I get."
Agent: "Could you let me know the make and model of your printer?" 
User: "I'm not sure on the exact model, but it's a [Printer Make]"
Agent: "[one sentence summary of where to best find the model]" 
User: "I found it, it's a [Printer Model]"
Agent: "Ok let me check the web. Ok here is what I found. The [Printer Make/Model] uses [Printer Ink Type] ink. Does this helps? These are results from google search."`,
          },
        ],
      },
      tools: [
        // there is a free-tier quota for search
        { googleSearch: {} },
        { functionDeclarations: [declaration] },
      ],
    });
  }, [setConfig, firstName]);

  useEffect(() => {
    const onToolCall = (toolCall: ToolCall) => {
      console.log(`got toolcall`, toolCall);
      const fc = toolCall.functionCalls.find(
        (fc) => fc.name === declaration.name,
      );
      if (fc) {
        const str = (fc.args as any).json_graph;
        setJSONString(str);
      }
      // send data for the response of your tool call
      // in this case Im just saying it was successful
      if (toolCall.functionCalls.length) {
        setTimeout(
          () =>
            client.sendToolResponse({
              functionResponses: toolCall.functionCalls.map((fc) => ({
                response: { output: { sucess: true } },
                id: fc.id,
              })),
            }),
          200,
        );
      }
    };
    client.on("toolcall", onToolCall);
    return () => {
      client.off("toolcall", onToolCall);
    };
  }, [client]);

  const embedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (embedRef.current && jsonString) {
      vegaEmbed(embedRef.current, JSON.parse(jsonString));
    }
  }, [embedRef, jsonString]);
  return <div className="vega-embed" ref={embedRef} />;
}

export const Altair = memo(AltairComponent);
